<template>
  <div class="case-list-wrap">
    <div class="tabs">
      <div
        :class="['tb', active == 1 ? 'active' : '']"
        @click="
          active = 1;
          onRefresh();
        "
      >
        线上产品
      </div>
      <div
        :class="['tb', active == 2 ? 'active' : '']"
        @click="
          active = 2;
          onRefresh();
        "
      >
        线下产品
      </div>
    </div>
    <div class="search">
      <div class="sc-ipt">
        <van-search
          v-model="searchKey"
          placeholder="输入姓名、订单编号、产品、资方"
          @search="onRefresh()"
          @clear="onRefresh()"
        />
      </div>
    </div>
    <div class="list" ref="list">
      <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
        <van-list
          v-model="loading"
          :immediate-check="false"
          :finished="finished"
          :finished-text="list.length ? '没有更多了' : '暂无数据'"
          @load="onLoad"
        >
          <CaseItem
            v-for="(item, index) in list"
            :key="index"
            :item="item"
            @click="detail(item)"
          />
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { columnAmount } from "@/utils/tool";

import { caseDetailsPage } from "@/api";

export default {
  name: "CaseList",
  components: {
    CaseItem: () => import("./components/CaseItem.vue"),
  },
  data() {
    return {
      columnAmount,
      active: 1,
      searchKey: "",

      /**
       * 列表
       */
      list: [],
      pageNo: 1,
      pageSize: 10,

      /**
       * 上下拉刷新
       */
      refreshing: false,
      loading: false,
      finished: false,

      //
    };
  },
  watch: {
    active: {
      immediate: true,
      handler() {},
    },
  },
  mounted() {
    this.getList();

    //
  },
  methods: {
    // 详情
    detail(row) {
      sessionStorage.caseDetailsItem = JSON.stringify({
        ...row,
        type: this.active,
      });
      this.$router.push(`/caseDetails`);
    },

    // 上拉加载
    onLoad() {
      (this.pageNo += 1) && this.getList();
    },

    // 下拉刷新
    onRefresh() {
      (this.pageNo = 1) && this.getList();
    },

    // 获取 列表
    async getList({ pageNo = this.pageNo, pageSize = this.pageSize } = {}) {
      try {
        if (pageNo == 1) {
          this.$toast.loading({ duration: 0 });
          this.$refs["list"].scrollTo({ top: 0, behavior: "smooth" });
        }

        const params = {
          pageNo,
          pageSize,
          ...this.filters,
          searchContent: this.searchKey,
          type: this.active,
          tipsStatus: this.$route.query.tipsStatus,
        };
        switch (params.tipsStatus) {
          case "EXPIRATION_REMIND":
            params.day = this.$route.query.day;
            break;
          case "OVERDUE_REMIND":
            params.startDay = this.$route.query.startDay;
            params.endDay = this.$route.query.endDay;
            break;
        }

        const data = await caseDetailsPage(params);

        this.refreshing = this.loading = false;
        const len = data.list.length;

        this.list = data.pageNo == 1 ? data.list : [...this.list, ...data.list];
        this.finished = len < pageSize;

        //
      } catch (err) {
        setImmediate(() => this.$toast(err));
      } finally {
        this.$toast.clear();
      }
    },

    //
  },
};
</script>

<style lang="scss" scoped>

.case-list-wrap {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  .tabs {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #f2f5f5;
    .tb {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      position: relative;
      width: 50%;
      height: 96px;
      &.active {
        &::after {
          position: absolute;
          bottom: 0;
          content: "";
          display: block;
          width: 64px;
          height: 6px;
          border-radius: 3px;
          background: #2396f5;
          left: calc((100% - 64px) / 2);
        }
      }
    }
  }
  .search {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    .sc-ipt {
      display: flex;
      align-items: center;
      padding: 20px;

      width: 686px;
      height: 72px;
      background: #f5f7f9;
      border-radius: 8px;
      /* .van-icon {
      } */
      input {
        margin-left: 16px;
        background: #f5f7f9;
        border: none;
        flex: 1;
        height: 50px;
        font-size: 28px;
      }
    }
  }
  .list {
    flex: 1;
    padding-bottom: 40px;
    background: #f5f7f9;
    overflow-y: auto;
    padding: 24px 24px;
  }
}
/deep/.van-field__control[type="search"] {
  font-size: 32px;
}
/deep/.van-search .van-cell {
  padding: 0;
}
/deep/.van-field__left-icon {
  margin-right: 24px;
}
/deep/.van-field__left-icon .van-icon {
  font-size: 32px;
}
/deep/.van-search {
  flex: 1;
  padding: 0;
}
/deep/.van-search__content {
  padding: 0;
}

</style>
